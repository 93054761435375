import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center text-8xl font-bold leading-50 mt-18" }
const _hoisted_2 = { class: "flex items-center justify-center text-lg mb-10" }
const _hoisted_3 = { class: "text-center text-dark-2 text-sm mb-10" }
const _hoisted_4 = { class: "flex justify-center items-center mt-5" }
const _hoisted_5 = { class: "px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("pages.error.oops")), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("pages.error.criticalError")), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("pages.error.text")), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_app_button, { onClick: _ctx.goToCheckStatus }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("pages.error.buttonLabel")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, {
          "button-style": "transparentUnderline",
          onClick: _ctx.retry
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("pages.error.retryLabel")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ], 64))
}