
import { defineComponent } from "vue";
import AppButton from "@/components/common/AppButton.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
    name: "ErrorPage",
    components: {
        AppButton
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const previousPath = store.state.app.previousPath || "/";

        const retry = () => {
            router.push({ path: previousPath });
        };
        const goToCheckStatus = () => {
            window.open("https://status.maatoo.io/", "_blank");
        };

        return {
            retry,
            goToCheckStatus
        };
    }
});
